const moment = window.moment || function () {};
moment.locale('zh-cn');

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (
  value,
  format = 'YYYY-MM-DD HH:mm:ss',
  unlimitedText = ''
) => {
  if (!value) return '';
  value = typeof value === 'string' ? value.replace(/-/g, '/') : value;
  let date = new Date(value);
  if (Number.isNaN(date.getTime())) return '';
  if (date.getFullYear() === 9999) return unlimitedText;
  return moment(date).format(format);
};

// 手机号脱敏
export const desensitization = (phoneNo) => {
  if (!phoneNo) return '';
  return phoneNo.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
};
/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if (isNaN(value)) return null;
  if ([null, undefined, ''].includes(value)) return null;
  if (value === 0) return parseFloat(0).toFixed(fixed);
  let num = value.toString().replace(/,/g, '');
  num = parseFloat(num || 0);
  num = num.toFixed(+fixed || 0).toString();
  num = num.split('.');
  if (num.length === 1) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'), num[1]].join('.');
};

// 比率修正
export const columnRatio = (value, placeholder = '-') => {
  if (value === null || isNaN(value)) return placeholder;
  value = Number(value).toFixed(2);
  return parseFloat(value);
};

// 获取某个数值的接近的整十、整百、整千等数值
export const numAlign = (num) => {
  if (!num) return 0;
  let _round = Math.ceil(parseInt(num.toString().split('.')[0]) / 2);
  _round = +_round
    .toString()
    .split('')
    .map((set, index) => (index === 0 ? 1 : 0))
    .join('');
  return Math.ceil(num / _round) * _round;
};

// 百分比格式化 带小数
export const percentFormat = (value) => {
  return value
    ? Number(value)
        .toFixed(2)
        .replace(/(\.?0*$|$)/, '%')
    : '0%';
};
