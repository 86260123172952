// 用户登录

// 缓存标识
export const CACHE_EXP_TIME = 1000 * 60 * 30;
export const AUTH_CACHE_KEY = '__screen_bi_scw_';
export const USER_CACHE_KEY = `${AUTH_CACHE_KEY}_user`;

// 保存用户信息
export const setUser = (user) => {
  if (!user) return;
  localStorage.setItem(
    USER_CACHE_KEY,
    JSON.stringify({
      exp: new Date().getTime() + CACHE_EXP_TIME,
      data: user,
    })
  );
};

// 移除用户信息
export const removeUser = () => {
  localStorage.removeItem(USER_CACHE_KEY);
};

// 获取用户信息
export const getUser = () => {
  try {
    let _cache = localStorage.getItem(USER_CACHE_KEY);
    if (!_cache) return null;
    _cache = JSON.parse(_cache);
    if (!_cache.exp) throw '数据无效';
    if (new Date().getTime() > +_cache.exp) throw '登录超时';
    return _cache.data;
  } catch (error) {
    removeUser();
    return null;
  }
};
