// 过滤器
import * as tools from '@/utils/tools'

export default {}.install = Vue => {

  // 注册工具函数为过滤器
  for (let f in tools) {
    Vue.filter(f, tools[f]);
  }

  // 零散的过滤器
  Vue.filter('version', () => 'SongChe Data Visual Platform');

}
