import Vue from "vue";
import VueRouter from "vue-router";
import { getUser } from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Base",
    component: () =>
      import(/* webpackChunkName: "router" */ "@/views/2020/Reception")
  },

  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "router" */ "@/views/Login")
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "router" */ "@/views/404")
  }
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () =>
  //     import(/* webpackChunkName: "router" */ '@/views/2020/Reception'),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "Base") {
    // 校验口令规则 32 位 MD5 字符串
    if (to.query.so && /^[0-9A-Z]{32}$/.test(to.query.so)) {
      // 这里的接口免鉴权，只要有32位字符串就可以无须登录
      next();
    } else {
      // 如果没有32位就登录访问
      // 获取本地缓存用户
      const _user = getUser();
      if (_user) {
        next();
      } else {
        next({ name: "Login" });
      }
    }
  } else {
    next();
  }
});

export default router;
